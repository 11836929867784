import axios from 'axios'
import {settings} from "@helpers";

export async function createArticle(articleId: any, timer?: any) {

    const res: any = await axios.request({
        url: `${settings.API_PREFIX}/statistics/articles/${articleId}`,
        method: 'GET',
        params: {timer}
    }).catch((e) => {console.error(e)});

    return res?.data?.success;
}

export const StatisticsApi = { createArticle }

import React, { useRef, useState } from "react";
import { Button, ButtonText, Tooltip, TooltipProps } from "@components";
import { CheckIcon, CopyIcon } from "@assets/icons";
import { useTranslator } from "@helpers";

import clsx from "clsx";
import "./CopyButton.less";

const prefixCls = "cmp-copy-button";


export interface CopyButtonProps {
    content?: string
    onClick?: (textareaRef: any, metaData: any) => void
    placement?: TooltipProps["placement"]
    className?: string
    tooltip?: string
    type?: 'dropdown' | 'text'
}


export const CopyButton: React.FC<CopyButtonProps> = (props: CopyButtonProps) => {

    const { onClick, content, className, type = 'dropdown', placement = "top", tooltip } = props;

    const { getLocalisation } = useTranslator("components")
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)

    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const handleChange = () => {}

    const handleClickCopy = () => {
        if (onClick) {
            onClick(textareaRef, {setLoading, setSuccess});
            return
        }

        textareaRef.current?.select()
        document.execCommand('copy')
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSuccess(true)
        })
        setTimeout(() => {
            setSuccess(false)
        }, 2000)
    }

    return (
        <>
            {type === 'dropdown' && <Tooltip
                title={tooltip || getLocalisation('copy_text')}
                placement={placement}
                visible={success ? false : undefined}
            >
                <Button
                    size="small"
                    type='dropdown'
                    loading={loading}
                    disabled={success}
                    beforeIcon={success ? <CheckIcon /> : <CopyIcon />}
                    onClick={handleClickCopy}
                    className={clsx(className, `${prefixCls}-copy`, {
                        [`${prefixCls}-success`]: success,
                    })}
                />
            </Tooltip>}
            {type === 'text' && <ButtonText
                size="small"
                loading={loading}
                disabled={success}
                beforeIcon={success ? <CheckIcon /> : <CopyIcon />}
                onClick={handleClickCopy}
                className={clsx(className, `${prefixCls}-copy`, {
                    [`${prefixCls}-success`]: success,
                })}
            />}
            <textarea
                value={content}
                onChange={handleChange}
                ref={textareaRef}
                className={`${prefixCls}-textarea`}
            />
        </>
    )
};
import React, {useState, useEffect, useContext} from "react";
import {getRoute, useBreakpoint} from "@helpers";
import {Drawer, KnowledgeList, Tooltip} from "@components";
import {KnowledgeViewItem, KnowledgeViewDrawer} from "@pages";
import {SearchContext} from "@context";
import { useNavigate, useLocation } from 'react-router-dom';
import {CloseIcon} from "@icons";
import { Tabs } from 'antd';
import clsx from "clsx";

import "./MainSearchResult.less";

export interface MainSearchResultProps {
}

const prefixCls = "page-main-search-result";

export const MainSearchResult = React.memo((props: any) => {
    const {context, dataItems} = props;

    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    const { 
        visibleSearch, setVisibleSearch, searchActiveKey, setSearchActiveKey,
        searchSelectedKeys, setSearchSelectedKeys
    } = useContext(SearchContext);

    const { isMobile, isTablet } = useBreakpoint();
    const [contextId, setContextId] = useState<any>(null);
    const [redirectUrl, setRedirectUrl] = useState<any>(null);
    const [visibleKnowledgeViewId, setVisibleKnowledgeViewId] = useState<any>(null);

    const handleClose = () => {
        setVisibleSearch(false);
    }

    const handleEditTab = (key: any) => {
        const newSelectedItems = searchSelectedKeys.filter((id: any) => `${id}` !== key);
        if (key === searchActiveKey) {
            setSearchActiveKey(newSelectedItems[0] && `${newSelectedItems[0]}`);
        }
        setSearchSelectedKeys(newSelectedItems)
    };

    const getTooltip = (id: any) => {
        const item = dataItems?.find((item: any) => id === item.id);

        return item?.title
    }

    const handleChangeTab = (searchActiveKey: any) => {
        setSearchActiveKey(searchActiveKey)
    };

    const handleKnowledgeClick = (id: any, e: MouseEvent) => {
        if (e.ctrlKey || e.button === 1) {
            window.open(getRoute('knowledgeView', { 'project': contextId, 'item': id }))
            return;
        }

        setSearchActiveKey(`${id}`);

        setSearchSelectedKeys(!searchSelectedKeys.includes(id) ? [id, ...searchSelectedKeys] : searchSelectedKeys)

        isTablet && setVisibleKnowledgeViewId(id);
    }

    const handleLinkEdit = () => {
        setVisibleSearch?.()
        setVisibleKnowledgeViewId(undefined);
    }

    const handleTabClick = (visibleKnowledgeViewId: any) => {

        if (!isTablet) {
            return
        }

        setVisibleKnowledgeViewId(visibleKnowledgeViewId);
        console.log(visibleKnowledgeViewId)
    }

    useEffect(() => {
        setContextId(context?.project_id)
    }, [context?.project_id])

    useEffect(() => {
        if (visibleSearch) {
            setRedirectUrl(window.location.pathname !== getRoute('search') && window.location.pathname)
        }
    }, [visibleSearch]);

    useEffect(() => {

        if (!visibleSearch && window.location.pathname.includes(getRoute('search'))) {
            if (!redirectUrl) {
                navigate(getRoute("home"));
            }
            else {
                window.history.replaceState(null, "", redirectUrl);
            }
        }

    }, [visibleSearch]);

    return (<>
        <Drawer
            mask={true}
            destroyOnClose={false}
            closable={!(isTablet || isMobile)}
            placement="top"
            visible={visibleSearch && !!dataItems}
            onClose={handleClose}
            size='large'
            className={clsx(`${prefixCls}-drawer`, {
                [`${prefixCls}-drawer-tablet`]: isTablet,
                [`${prefixCls}-drawer-mobile`]: isMobile
            })}
        >
            <section className={clsx(`${prefixCls}-list`)}>

                {dataItems && <KnowledgeList
                    onClick={handleKnowledgeClick}
                    size="small"
                    items={dataItems}
                />}

            </section>

            <section className={clsx(`${prefixCls}-view`)}>
                {contextId && searchSelectedKeys && !!searchSelectedKeys.length && <Tabs
                    hideAdd
                    size="small"
                    type="editable-card"
                    activeKey={searchActiveKey}
                    onChange={handleChangeTab}
                    onEdit={handleEditTab}
                    onTabClick={handleTabClick}
                    tabPosition="left"
                >
                    {searchSelectedKeys.map((id: any) => <Tabs.TabPane
                        key={`${id}`}
                        tab={<Tooltip 
                            title={getTooltip(id)}
                            placement="left"
                        >{`ID: ${id}`}</Tooltip>}
                        closable={true}
                        closeIcon={<CloseIcon />}

                    >
                        {!isTablet && <KnowledgeViewItem
                            itemId={id}
                            contextId={contextId}
                            onLinkEdit={handleLinkEdit}
                        />}
                    </Tabs.TabPane>)}

                </Tabs>}
            </section>


        </Drawer>

        <KnowledgeViewDrawer
            visible={isTablet && !!visibleKnowledgeViewId}
            onClose={() => setVisibleKnowledgeViewId(false)}
            itemId={visibleKnowledgeViewId}
            contextId={contextId}
            onLinkEdit={handleLinkEdit}
        />
    </>);
});
import {createContext} from 'react';

export type SearchContextProps = {
  visibleSearch?: any
  setVisibleSearch?: any
  setSearchFilterValues?: any
  searchFilterValues?: any
  searchSelectedKeys?: any
  setSearchSelectedKeys?: any
  searchActiveKey?: any
  setSearchActiveKey?: any
}

const defaultValue: SearchContextProps = {
  visibleSearch: null,
  setVisibleSearch: null,
  searchFilterValues: null,
  setSearchFilterValues: null,
  searchSelectedKeys: null,
  setSearchSelectedKeys: null,
  searchActiveKey: null,
  setSearchActiveKey: null
}

export const SearchContext = createContext(defaultValue);
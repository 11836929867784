import {Grid} from 'antd';
import {TranslatorContext} from "@context";
import {useCallback, useContext, useEffect} from "react";
import {
    CzechIcon, FranceIcon, GermanyIcon, PortugalIcon,
    RussiaIcon, SpainIcon, UKIcon, UkraineIcon, AnotherCountryIcon, ChineseIcon
} from '@assets/icons';
import { RoutesItemProps } from '@types';
import { routeItems } from './Routes';

export const getLanguageIcon = (id: any) => {
    switch (id) {
        case "DEU": return <GermanyIcon />;
        case "POR": return <PortugalIcon />;
        case "RUS": return <RussiaIcon />;
        case "UKR": return <UkraineIcon />;
        case "CES": return <CzechIcon />;
        case "ENG": return <UKIcon />;
        case "SPA": return <SpainIcon />;
        case "FRA": return <FranceIcon />;
        case "ZHO": return <ChineseIcon />;
        default: return <AnotherCountryIcon />
    }
}

const reactReplace = (value: string, replaceConfig: any, parserHTML?: boolean) => {
    let parts = [value];

    Object.keys(replaceConfig).forEach((replaceKey: string) => {

        const ReactElement = replaceConfig[replaceKey];
        const result: any = [];
        parts.forEach((part: string) => {
            // eslint-disable-next-line
            result.concat(part.split(replaceKey).map((value: any, i: number) => {

                result.push(parserHTML ? <span key={result.length} dangerouslySetInnerHTML={{__html: value}}/> : value);

                if (ReactElement && i + 1 !== part.split(replaceKey).length) {
                    result.push(parserHTML ? <span key={result.length}>{ReactElement}</span> : ReactElement);
                }
            }))
        })

        parts = result;
    });

    return parts
}

export const useBreakpoint = () => {
    const screens = Grid.useBreakpoint();
    const isMobile = (screens: any): boolean => !screens["md"];
    const isTablet = (screens: any): boolean => !screens["lg"];

    return {
        isMobile: isMobile(screens),
        isTablet: isTablet(screens)
    }
}

type TranslatorReplaceConfigProps = {
    [key: string]: any
}

export const useTranslator = (key: string) => {
    const { translator } = useContext(TranslatorContext)
    const localisation = translator[key];

    const getLocalisation = useCallback((key: string, parserHTML?: any, replaceConfig?: TranslatorReplaceConfigProps) => {

        if (!localisation[key]) {
            return null
        }
        const _replaceConfig = typeof parserHTML === "boolean" ? replaceConfig : parserHTML;
        const _parserHTML = typeof parserHTML === "boolean" && parserHTML;

        if (_replaceConfig) {
            return reactReplace(localisation[key], _replaceConfig, _parserHTML);
        }

        return _parserHTML ? <span dangerouslySetInnerHTML={{__html: localisation[key]}}/> : localisation[key];
    },[localisation])

    return { getLocalisation }
}


export interface UseInfiniteScrollProps {
    callback?: () => void;
    triggerRef: any;
    root?: any;
}

export function useInfiniteScroll({ callback, triggerRef, root }: UseInfiniteScrollProps) {

    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        const triggerElement = triggerRef.current;
        if (callback && triggerElement) {

            const options = {
                root: root || document.querySelector('#root'),
                rootMargin: '0px',
                threshold: 1.0,
            };

            observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    callback();
                }
            }, options);

            observer.observe(triggerElement);
        }

        return () => {
            if (observer && triggerElement) {
                observer.unobserve(triggerElement);
            }
        };
    }, [callback, triggerRef]);
}

interface RouterParams {
    'project': string | number;
    'section': string | number;
    'item': string | number;
    'name': string | number
}

export const getRoute = (key: RoutesItemProps['key'], params?: Partial<RouterParams>) => {
    let path = routeItems.filter((item: RoutesItemProps) => (
        item.key === key
    ))[0].path

    if (!params) {
        return path || ''
    }

    Object.entries(params).forEach(([key, value]) => (
        path = path?.replace(new RegExp(`:${key}`, 'g'), String(value))
    ))

    return path || ''
}

export const removeDuplicates = <T,>(arr: T[], key: keyof T) => {
    const mapKey: any = {};

    return arr.filter((row: T) => {

        if (mapKey[row[key]]) {
            return false
        }

        return (mapKey[row[key]] = true)
    })
}
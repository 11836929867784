import {RoutesItemProps} from '@types';
import {
    KnowledgeSections, Profile, Home, Users, Files, Knowledge, Roles,
    KnowledgeView, Projects, Controls, Procedures, RoleTemplates, Permissions,
    Page404, Help, History, ReportArticles, Search
} from '@pages';
    
export const routeItems: Array<RoutesItemProps> = [
    {
        path: '/home',
        key: 'home',
        Component: Home
    },
    {
        path: '/search',
        key: 'search',
        Component: Search
    },
    {
        path: '/project/:project/files',
        key: 'files',
        Component: Files
    },
    {
        path: '/profile',
        key: 'profile',
        Component: Profile
    },
    {
        path: '/help',
        key: 'help',
        Component: Help
    },
    {
        path: '/project/:project/knowledge/section/:section',
        key: 'knowledge',
        control: "menu.item.knowledge.view",
        Component: Knowledge
    },
    {
        path: '/project/:project/knowledge/section/:section/item/:item',
        key: 'knowledgeItem',
        control: "menu.item.knowledge.view",
        Component: Knowledge
    },
    {
        path: '/project/:project/knowledge/sections',
        key: 'knowledgeSections',
        control: "menu.item.knowledge.sections.view",
        Component: KnowledgeSections
    },
    {
        path: '/project/:project/knowledge/view/item/:item',
        key: 'knowledgeView',
        control: "menu.item.knowledge.view",
        Component: KnowledgeView
    },
    {
        path: '/project/:project/users',
        key: 'users',
        control: "menu.item.users.view",
        Component: Users
    },
    {
        path: '/project/:project/history',
        key: 'history',
        // control: "menu.item.users.view",
        Component: History
    },
        {
        path: '/project/:project/history/:item/:name',
        key: 'historyItem',
        // control: "menu.item.users.view",
        Component: History
    },
    {
        path: '/system/controls',
        key: 'controls',
        control: "menu.item.controls.view",
        Component: Controls
    },
    {
        path: '/system/procedures',
        key: 'procedures',
        control: "menu.item.procedures.view",
        Component: Procedures
    },
    {
        path: '/project/:project/system/roles',
        key: 'roles',
        control: "menu.item.roles.view",
        Component: Roles
    },
    {
        path: '/system/projects',
        key: 'projects',
        control: "menu.item.projects.view",
        Component: Projects
    },
    {
        path: '/system/role/templates',
        key: 'roleTemplates',
        control: "menu.item.role.templates.view",
        Component: RoleTemplates
    },
    {
        path: '/system/permissions',
        key: 'permissions',
        control: "menu.item.permissions.view",
        Component: Permissions
    },
    {
        path: '/report/articles',
        key: 'reportArticles',
        control: "menu.item.reports.articles.view",
        Component: ReportArticles
    },
    {
        path: '*',
        Component: Page404
    }
];


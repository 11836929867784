import {useContext, useEffect} from "react";
import {SearchContext} from "@context";
import { useSearchParams } from "react-router-dom";

import "./Search.less";

export const Search = () => {
    const {visibleSearch, setVisibleSearch, setSearchFilterValues, setSearchSelectedKeys, setSearchActiveKey} = useContext(SearchContext);
    const [searchParams] = useSearchParams();

    useEffect(() => {

        if (visibleSearch || !searchParams.get("filter")) {
            return
        }

        const searchFilterValues = JSON.parse(searchParams.get("filter") || "");
        const searchActiveKey = searchParams.get("activeKey");
        const searchSelectedKeys = JSON.parse(searchParams.get("selected") || "");
        setSearchSelectedKeys(searchSelectedKeys || []);
        setSearchFilterValues(searchFilterValues);
        setSearchActiveKey(searchActiveKey);
        setVisibleSearch(true);

    }, [searchParams])

    return null;
};
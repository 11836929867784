import React, {useCallback, useContext, useEffect, useState} from "react";
import {Loader, ViewItem} from "@components";
import {FileItemProps, KeywordsProps, KnowledgeItemProps} from "@types";
import {KeywordsApi, KnowledgesApi, StatisticsApi} from "@api";
import {FavoritesContext} from "@context";

import "./KnowledgeViewItem.less";
import {useNavigate, useParams} from "react-router-dom";
import { KnowledgeViewEmpty } from "./KnowledgeViewEmpty";
import {KnowledgeItemVersions} from "@pages/Knowledge/KnowledgeItemVersions";
import { getRoute } from "@helpers";

const prefixCls = "page-knowledge-view-item";

export interface KnowledgeViewItemProps {
    itemId?: any,
    contextId?: any
    onLinkEdit?: () => void
}

export const KnowledgeViewItem: React.FC<KnowledgeViewItemProps> = (props: KnowledgeViewItemProps) => {
    const navigate = useNavigate();

    const {itemId, contextId, onLinkEdit} = props;

    const {section}: any = useParams();
    const { loadFavoriteItems } = useContext(FavoritesContext);

    const [keywords, setKeywords] = useState<KeywordsProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showEmptyPage, setShowEmptyPage] = useState<boolean>(false);
    const [record, setRecord] = useState<KnowledgeItemProps>();
    const [formVersionId, setFormVersionId] = useState<KnowledgeItemProps["id"]>();

    const handleClickFavorites = async (id: any, { setLoading }: any) => {
        if (!record) {
            return
        }
        setLoading(true);

        const result = await KnowledgesApi[record.is_favorite ? "removeFavorites" : "updateFavorites"](id);

        if (result) {
            await loadFavoriteItems();
            setRecord({
                ...record,
                is_favorite: !record.is_favorite,
            });
        }

        setLoading(false)
    }

    const handleChangeKeywords = async (values: any) => {
        const result = await KeywordsApi.update(itemId, values)

        if (result && itemId) {
            const record = await KnowledgesApi.getPublishRecord(itemId);
            record && setRecord(record);
        }
    }

    const handleCreateKeywords = async (label: any) => {
        const result = await KeywordsApi.createKeywords(itemId, {label})
        
        if (result && itemId) {
            const record = await KnowledgesApi.getPublishRecord(itemId);
            fetchKeywords().catch(console.error)
            record && setRecord(record);
        }
    }

    const handleVersionClick = (id: any) => {
        setFormVersionId(id)
    }

    const handleCloseVersion = () => {
        setFormVersionId(undefined)
    }

    const fetchInit = useCallback(async (id) => {
        setLoading(true);
        const record = id ? await KnowledgesApi.getPublishRecord(id) : undefined;

        setShowEmptyPage(!record);

        if (record) {

            const {projects} = record;

            const project = projects?.find(({value}: any) => parseInt(value) === parseInt(contextId));

            setRecord(project ? {
                ...record,
                files: (record.files || []).filter(({archived}: FileItemProps) => !archived)
            } : undefined);

            setShowEmptyPage(!project);
        }

        setLoading(false);

    }, [contextId])

    const fetchKeywords = useCallback(async () => {
        const keywords = await KeywordsApi.getAllKeywords();
        setKeywords(keywords || [])
    }, [])

    const handleChangeLanguage = (value: any) => {
        const langRecord = record?.languages?.find((item: any) => item.id === value);

        if (langRecord) {
            fetchInit(langRecord.article_id).catch(console.error)
        }
    }

    const handleLinkEdit = () => {
        if (!record) {
            return;
        }
        const {projects} = record;

        if (!projects) {
            return;
        }

        const project = projects?.find(({value}: any) => parseInt(value) === parseInt(contextId)) || projects[0];

        navigate(getRoute('knowledgeItem', {
            'project': (project && project.value) || contextId,
            'section': record.section_id,
            'item': record?.id
        }));

        onLinkEdit?.()
    }

    const handleCopyContent = async () => {
        await StatisticsApi.createArticle(itemId);
    }

    useEffect(() => {
        fetchInit(itemId).catch(console.error)
    }, [section, itemId, fetchInit]);

    useEffect(() => {
        fetchKeywords().catch(console.error)
    }, [fetchKeywords]);

    // useEffect(() => {
    //     const timerStamp = (new Date()).getTime();
    //     console.log(itemId)
        
    //     return () => {
    //         console.log((new Date()).getTime() - timerStamp);
    //     }

    // }, [])

    return (
        <div className={prefixCls}>
            <Loader
                spinning={loading}
                size="middle"
            >
                {record && !showEmptyPage && <ViewItem
                    simple
                    record={record}
                    keywords={keywords}
                    onCopyContent={handleCopyContent}
                    onLinkEdit={handleLinkEdit}
                    onVersionClick={handleVersionClick}
                    onClickFavorites={handleClickFavorites}
                    onChangeKeywords={handleChangeKeywords}
                    onCreateKeywords={handleCreateKeywords}
                    onChangeLanguage={handleChangeLanguage}
                    targetAffix={'.cmp-drawer-wrapper-body'}
                />}

                {showEmptyPage && <KnowledgeViewEmpty itemId={itemId}/>}

            </Loader>

            <KnowledgeItemVersions
                simple
                id={formVersionId}
                visible={formVersionId}
                onClose={handleCloseVersion}
            />
        </div>
    );
};